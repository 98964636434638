import { useState } from "react";
import BreadCrump from "../../components/BreadCrump/BreadCrump";
import "./Contacts.css";

import * as baseService from "../../services/BaseService";
import { toast } from "react-toastify";
import BtnLoader from "../../components/utils/BtnLoader/BtnLoader";
import { Helmet } from "react-helmet-async";
export default function Contacts() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  // Handles changes to form input fields
  const handleChange = (e) => {
    setFormData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  // Handles form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true

    // Submitting the form data using the contactRequest service
    baseService
      .contactRequest(formData)
      .then((result) => {
        if (result.success) {
          // Reset form data on successful submission
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error("Възникна грешка. Моля опитайте отново.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Helmet>
        <title>{"Контакти"}</title>
        <meta
          name="description"
          content="Свържете се с нас за повече информация и съдействие. Намерете нашите контактни данни, включително телефон, имейл и адрес на офиса"
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Контакти" data-rh="true" />
        <meta
          property="og:description"
          content="Свържете се с нас за повече информация и съдействие. Намерете нашите контактни данни, включително телефон, имейл и адрес на офиса."
          data-rh="true"
        />
        <meta property="og:url" content={window.location.href} data-rh="true" />
        <meta property="og:type" content="website" data-rh="true" />
        <meta
          property="og:image"
          content="https://promosaleplovdiv.bg/build/assets/about-1a3fa074.jpg"
          data-rh="true"
        />{" "}
        <meta property="og:image:width" content="200" data-rh="true" />
        <meta property="og:image:height" content="200" data-rh="true" />
        <meta
          property="og:site_name"
          content="PromoSale Plovdiv"
          data-rh="true"
        />
      </Helmet>
      <BreadCrump title="Контакти" />
      <section className="section white">
        <div className="content-cont form-groups">
          <div className="inner">
            <div className="contact">
              <form
                action="scripts/contact.php"
                method="post"
                id="contact-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="contact-name"
                        name="name"
                        placeholder="Вашето име"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="contact-email"
                        name="email"
                        placeholder="Вашият имейл"
                        value={formData.email}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="contact-phone"
                        value={formData.phone}
                        name="phone"
                        placeholder="Вашият телефон"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="contact-subject"
                        name="subject"
                        value={formData.subject}
                        placeholder="Пояснение (по желание)"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        value={formData.message}
                        placeholder="Вашето съобщение"
                        rows={7}
                        defaultValue={""}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-form-group">
                  <button
                    type="submit"
                    className="button light-blue"
                    disabled={loading}
                  >
                    {loading ? <BtnLoader /> : "Изпрати"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="contact-wrapper">
            <div className="contact-details">
              <div className="item">
                <div className="icon">
                  <i className="ion-ios-location-outline" />
                </div>
                <div className="content">
                  <h6>АДРЕС</h6>
                  <span>гр. Пловдив ул. Васил Левски 205</span>
                </div>{" "}
              </div>{" "}
              <div className="item">
                <div className="icon">
                  <i className="ion-ios-telephone-outline" />
                </div>
                <div className="content">
                  <h6>ТЕЛЕФОН</h6>
                  <a href="tel:0884 27 77 76">0884 27 77 76</a>
                  <br />
                  <a href="tel:0879 87 00 00">0879 87 00 00</a>
                </div>{" "}
              </div>{" "}
              <div className="item">
                <div className="icon">
                  <i className="ion-ios-email-outline" />
                </div>
                <div className="content">
                  <h6>ИМЕЙЛ</h6>
                  <a href="mailto:promosaleplovdivsm@gmail.com">
                    promosaleplovdivsm@gmail.com
                  </a>{" "}
                  <br />
                  <a href="mailto:promosaleplovdiv@abv.bg">
                    promosaleplovdiv@abv.bg
                  </a>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="maps-cont">
          <iframe
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11824.240298296136!2d24.7360175!3d42.191783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14acd359c5dd91d7%3A0x6c0fd7e4819ff86f!2sPromosale%20Plovdiv!5e0!3m2!1sbg!2sbg!4v1681303995033!5m2!1sbg!2sbg"
            width="100%"
            height={350}
            allowFullScreen="allowfullscreen"
          />
        </div>
      </section>
    </>
  );
}
