export const filterCars = (formData, cars) => {
    let updatedCars = [...cars];
    Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (value) {
            if (key === "price_from") {
                updatedCars = updatedCars.filter(
                    (car) => Number(car.price) >= value
                );
            } else if (key === "price_to") {
                updatedCars = updatedCars.filter(
                    (car) => Number(car.price) <= value
                );
            } else if (key === "milage") {
                updatedCars = updatedCars.filter(
                    (car) => Number(car.mileage) <= value
                );
            } else if (key === "year") {
                updatedCars = updatedCars.filter(
                    (car) => Number(car.year.value) >= value
                );
            } else {
                updatedCars = updatedCars.filter(
                    (car) => car[key]?.field_id == value || car[key] == value
                );
            }
        }
    });
    return updatedCars;
};
