import "./NavBar.css";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="navigation clearfix">
            <div className="logo">
              <NavLink to="/">
                <img
                  alt="Promosale"
                  src={logo}
                  className="nav-img-responsive"
                />
              </NavLink>
            </div>
            <nav className="main-nav">
              <ul className="list-unstyled">
                <li className="active">
                  <NavLink
                    to="/"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            borderTopColor: "rgba(252, 185, 0, 1)",
                          }
                        : {};
                    }}
                  >
                    Начало
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/zayavka-za-avtomobil"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            borderTopColor: "rgba(252, 185, 0, 1)",
                          }
                        : {};
                    }}
                  >
                    Заявка за автомобил
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/nalichni-avtomobili"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            borderTopColor: "rgba(252, 185, 0, 1)",
                          }
                        : {};
                    }}
                  >
                    Налични автомобили
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/za-nas"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            borderTopColor: "rgba(252, 185, 0, 1)",
                          }
                        : {};
                    }}
                  >
                    За нас
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/kontakti"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            borderTopColor: "rgba(252, 185, 0, 1)",
                          }
                        : {};
                    }}
                  >
                    Контакти
                  </NavLink>
                </li>
              </ul>
            </nav>
            <a
              href="#"
              className="responsive-menu-open"
              onClick={handleMenuToggle}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </header>
      {isMenuOpen && (
        <div className="responsive-menu">
          <a
            href="#"
            className="responsive-menu-close"
            onClick={handleMenuToggle}
          >
            <i className="ion-android-close" />
          </a>
          <nav className="responsive-nav">
            <ul className="list-mobile">
              {/* Responsive navigation links */}
              <li>
                <NavLink to="/" onClick={handleMenuToggle}>
                  Начало
                </NavLink>
              </li>
              <li>
                <NavLink to="/zayavka-za-avtomobil" onClick={handleMenuToggle}>
                  Заявка за автомобил
                </NavLink>
              </li>
              <li>
                <NavLink to="/nalichni-avtomobili" onClick={handleMenuToggle}>
                  Налични автомобили
                </NavLink>
              </li>
              <li>
                <NavLink to="/za-nas" onClick={handleMenuToggle}>
                  За нас
                </NavLink>
              </li>
              <li>
                <NavLink to="/kontakti" onClick={handleMenuToggle}>
                  Контакти
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}
