import { useState } from "react";
import "./CarsSort.css";

export default function CarsSort({ setFiltredCars, cars }) {
  const [selectedSort, setSelectedSort] = useState({
    type: null,
    order: null,
  });

  // Function to handle sorting of cars based on the selected type and order
  const handleSort = (type) => {
    let newOrder = "asc";

    // Toggle order if the same sort type is selected again
    if (selectedSort.type === type && selectedSort.order === "asc") {
      newOrder = "desc";
    }

    // Update state with the new sort type and order
    setSelectedSort({ type, order: newOrder });

    // Create a new array with sorted cars based on the selected sort type and order
    const sortedCars = [...cars].sort((a, b) => {
      if (type === "newest") {
        return newOrder === "asc"
          ? new Date(b.created_at) - new Date(a.created_at)
          : new Date(a.created_at) - new Date(b.created_at);
      } else if (type === "price") {
        return newOrder === "asc" ? a.price - b.price : b.price - a.price;
      } else if (type === "mileage") {
        return newOrder === "asc"
          ? a.mileage - b.mileage
          : b.mileage - a.mileage;
      } else if (type === "a-z") {
        if (newOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          console.log(a);
          return b.name.localeCompare(a.name);
        }
      }
      return 0; // Default return value if no valid sort type is matched
    });
    setFiltredCars(sortedCars);
  };

  // Function to determine the icon class based on the selected sort type and order
  const getIconClass = (type) => {
    if (selectedSort.type === type) {
      return selectedSort.order === "asc"
        ? "fa-solid fa-arrow-up-wide-short"
        : "fa-solid fa-arrow-down-wide-short";
    }
    return "fa-solid fa-sort";
  };

  return (
    <div className="sort_cont">
      <ul className="sort-items">
        <li className="sort-item" onClick={() => handleSort("newest")}>
          <i className={getIconClass("newest")}></i>
          <strong>Най-нови</strong>
        </li>
        <li className="sort-item" onClick={() => handleSort("price")}>
          <i className={getIconClass("price")}></i>
          <strong>Цена</strong>
        </li>
        <li className="sort-item" onClick={() => handleSort("a-z")}>
          <i className={getIconClass("a-z")}></i>
          <strong>А-Я</strong>
        </li>
        <li className="sort-item" onClick={() => handleSort("mileage")}>
          <i className={getIconClass("mileage")}></i>
          <strong>Пробег</strong>
        </li>
      </ul>
    </div>
  );
}
