import React, { useEffect, useState } from "react";
import CarCard from "../../components/CarCard/CarCard";
import "./AvailableCars.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { AiOutlineArrowUp } from "react-icons/ai";
import BreadCrump from "../../components/BreadCrump/BreadCrump";
import * as baseService from "../../services/BaseService";
import Loader from "../../components/utils/Loader/Loader";
import CarsFilter from "../../components/Filter/CarsFilter";

export default function AvailableCars() {
  const [cars, setCars] = useState(null);
  const [filtredCars, setFiltredCars] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Effect to fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Load initial cars
        const result = await baseService.loadInitCars();
        if (result.success) {
          setCars(result.cars);
          setFiltredCars(result.cars);

          // Load additional cars
          const moreCarsResult = await baseService.loadCars();
          if (moreCarsResult.success) {
            setCars(moreCarsResult.cars);
            setFiltredCars(moreCarsResult.cars);
          } else {
            console.log(moreCarsResult.message);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoaded(true); // Indicate that data has been loaded
      }
    };

    fetchData();
  }, []);

  // Effect to restore scroll position after data has loaded
  useEffect(() => {
    if (dataLoaded) {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");

      const scrollPosition = parseInt(savedScrollPosition, 10);

      if (!isNaN(scrollPosition) && scrollPosition >= 0) {
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
        sessionStorage.removeItem("scrollPosition"); // Clean up the saved position
      }
    }
  }, [dataLoaded]);

  // Function to handle scroll-to-top action
  const handleScrollClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Show a loader while filtered cars are being fetched
  if (!filtredCars) {
    return <Loader />;
  }

  return (
    <>
      <BreadCrump title="Налични автомобили" />
      <CarsFilter
        setFiltredCars={setFiltredCars}
        cars={cars}
        filtredCars={filtredCars}
      />
      <InfiniteScroll
        dataLength={cars.length}
        endMessage={
          <button
            mb={"1"}
            onClick={handleScrollClick}
            variant="outline-secondary"
            className="test-btn"
          >
            Видяхте всички налични автомобили{" "}
            <AiOutlineArrowUp boxSize={5} ml="8px" />
          </button>
        }
      >
        <div className="cars-container">
          {filtredCars.length > 0 ? (
            filtredCars.map((car) => <CarCard key={car.id} car={car} />)
          ) : (
            <h1 className="no-results">Няма намерени резултати</h1>
          )}
        </div>
      </InfiniteScroll>
    </>
  );
}
