import "./CarsFilter.css";

import { useState, useEffect } from "react";
import * as baseService from "../../services/BaseService";
import { filterCars } from "../../utils/filter";
import CarsSort from "../CarsSort/CarsSort";
export default function CarsFilter({ setFiltredCars, cars }) {
  const [formData, setFormData] = useState({
    marka: "",
    model: "",
    year: "",
    price_from: "",
    price_to: "",
    milage: "",
    car_type: "",
    engine_type: "",
    transmission: "",
    nup: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [requestDataModels, setRequestDataModels] = useState(null);
  const [requestData, setRequestData] = useState(null);

  // Fetch filter data (e.g., car makes and other options) on component mount
  useEffect(() => {
    baseService.getFilterData().then((result) => {
      if (result.success) {
        setRequestData(result.fields);
      } else {
        console.log(result.message);
      }
    });
  }, []);

  // Filter cars whenever formData or cars change
  useEffect(() => {
    const filteredCars = filterCars(formData, cars);
    setFiltredCars(filteredCars);
  }, [formData, cars]);

  if (!requestData) {
    return null;
  }

  // Toggle the visibility of the filter section
  const toggleFilter = () => {
    setShowFilter((state) => !state);
  };

  // Handle changes to form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "marka") {
      const marka = requestData.marka.find((marka) => marka.id == value);

      if (value === "") {
        setRequestDataModels(null);
      } else {
        handleMakeChange(marka?.label);
      }

      // Reset model to empty string
      setFormData((state) => ({
        ...state,
        model: "",
        [name]: value,
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  // Fetch model data based on selected make
  const handleMakeChange = (make) => {
    baseService.getFilterModelsData(make).then((result) => {
      if (result.success) {
        setRequestDataModels(result.models);
      } else {
        console.log(result.message);
      }
    });
  };
  return (
    <div className="filter-cont">
      <h4 className="title">
        <div className="wrapper" onClick={toggleFilter}>
          <i className="fa-solid fa-filter"></i>
          <strong>Филтри</strong>
          {showFilter ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
        <CarsSort setFiltredCars={setFiltredCars} cars={cars} />
      </h4>
      {showFilter && (
        <div className="form-group">
          <div className="form-group-item">
            <label htmlFor="make">Марка</label>
            <select name="marka" id="marka" required onChange={handleChange}>
              <option value="">Избери</option>
              {requestData?.marka.map((marka) => (
                <option value={marka.id} key={marka.id}>
                  {marka.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Модел</label>
            <select name="model" id="model" required onChange={handleChange}>
              <option value="">Избери</option>
              {requestDataModels &&
                requestDataModels.map((model) => (
                  <option value={model.id} key={model.id}>
                    {model.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Дата на първа регистрация</label>
            <select name="year" id="model" required onChange={handleChange}>
              <option value="">Избери</option>
              {requestData?.year.map((year) => (
                <option value={year.label} key={year.id}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-block-single">
            <div className="form-block-inside">
              <label>Цена от:</label>
              <input name="price_from" defaultValue="" onBlur={handleChange} />
            </div>
            <div className="form-block-inside">
              <label>Цена до:</label>
              <input name="price_to" defaultValue="" onBlur={handleChange} />
            </div>
          </div>
          <div className="form-group-item">
            <label htmlFor="milage">Пробег</label>
            <input
              type="text"
              name="milage"
              className="form-control"
              id="milage"
              onBlur={handleChange}
            />
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Тип</label>
            <select
              name="car_type"
              id="car_type"
              required
              onChange={handleChange}
            >
              <option value="">Избери</option>
              {requestData?.category.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Двигател</label>
            <select
              name="engine_type"
              id="engine_type"
              required
              onChange={handleChange}
            >
              <option value="">Избери</option>
              {requestData?.engine_type.map((engine_type) => (
                <option value={engine_type.id} key={engine_type.id}>
                  {engine_type.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Трансмисия</label>
            <select
              name="transmission"
              id="transmission"
              required
              onChange={handleChange}
            >
              <option value="">Избери</option>
              {requestData?.transmission.map((transmission) => (
                <option value={transmission.id} key={transmission.id}>
                  {transmission.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group-item">
            <label htmlFor="model">Състояние</label>
            <select
              name="nup"
              id="transmission"
              required
              onChange={handleChange}
            >
              <option value="">Избери</option>
              {requestData?.nup.map((nup) => (
                <option value={nup.id} key={nup.id}>
                  {nup.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}
