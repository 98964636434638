import "./BreadCrump.css";

export default function BreadCrump({ title, car_price }) {
    return (
        <section className="parallax-section">
            <div className="parallax-inner">
                <div className="bg" data-bg=""></div>
                <div className="overlay"></div>
            </div>
            <div className="container">
                <div className="page-title">
                    <div className="row">
                        <div className="col-md-12 main-page-title">
                            <h2>{title}</h2>
                        </div>
                    </div>
                </div>
            </div>
            {car_price && (
                <div className="car_price">
                    <sapn className="car_price_btn">{car_price}</sapn>
                </div>
            )}
        </section>
    );
}
