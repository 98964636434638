import { useState } from "react";
import "./OfferForm.css";
import { CgClose } from "react-icons/cg";

import * as baseService from "../../services/BaseService";
import { toast } from "react-toastify";
import BtnLoader from "../utils/BtnLoader/BtnLoader";
export default function OfferForm({ car }) {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    additional_info: "",
  });
  const handleClose = () => {
    setIsOpen(false);
  };

  // Return null to render nothing if the form is not open
  if (!isOpen) {
    return null;
  }

  // Function to handle changes to form input fields
  const handleFormData = (e) => {
    setFormData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare data for submission based on car object structure
    let data;
    if (car.fields) {
      data = {
        make: car.fields.marka,
        model: car.fields.model,
        first_registration_date: car.fields.year,
        milage: car.fields.km,
        type: car.fields.category,
        transmission: car.fields.transmission,
        idf: car.id,
        price: `${car.fields.price} ${car.fields.currency}`,
        engine: car.fields.engine_type,
        ...formData,
      };
    } else {
      data = {
        make: car.marka.value,
        model: car.model.value,
        first_registration_date: car.year.value,
        milage: car.mileage,
        type: car.car_type.value,
        transmission: car.transmission.value,
        idf: car.id,
        price: `${car.price} ${car.currency.value}`,
        engine: car.engine_type.value,
        ...formData,
      };
    }

    setLoading(true); // Set loading state to true before submission

    // Submit form data to the server
    baseService
      .getOffer(data)
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          setIsOpen(false);

          // Reset form data after successful submission
          setFormData({
            name: "",
            phone: "",
            email: "",
            additional_info: "",
          });
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Възникна грешка. Моля опитайте отново.");
      })
      .finally(() => {
        setLoading(false); // Reset loading state regardless of success or failure
      });
  };
  return (
    <div className="si-form-sidebar-offer show">
      <div className="si-form-btn">
        <a href="#" className="si-form-sidebar-close" onClick={handleClose}>
          <CgClose />
        </a>
        <h4>Поискай оферта</h4>
        <h5 className="si-h5">
          Свържете се с нас! Ние ще обработим Вашата заявка възможно най-бързо и
          ще се свържем с Вас.
        </h5>
        <div className="wpcf7 js" id="wpcf7-f22658-o1" lang="bg-BG" dir="ltr">
          <div className="screen-reader-response">
            <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
          </div>
          <form
            onSubmit={handleSubmit}
            method="post"
            className="wpcf7-form init"
            aria-label="Форма за контакти"
          >
            <div className="form-block make">
              <div className="form-block-inside">
                <p>
                  <label>
                    {" "}
                    Вашето име
                    <br />
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="your-name"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        value={formData.name}
                        onChange={handleFormData}
                        type="text"
                        name="name"
                        required
                      />
                    </span>{" "}
                  </label>
                </p>
              </div>
            </div>
            <div className="form-block make">
              <div className="form-block-inside">
                <p>
                  <label>
                    {" "}
                    Вашият телефон
                    <br />
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="text-618"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        id="user_phone"
                        aria-required="true"
                        aria-invalid="false"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleFormData}
                        required
                      />
                    </span>{" "}
                  </label>
                </p>
              </div>
            </div>
            <div className="form-block make">
              <div className="form-block-inside">
                <p>
                  <label>
                    {" "}
                    Вашият имейл
                    <br />
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="your-email"
                    >
                      <input
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                        aria-required="true"
                        aria-invalid="false"
                        type="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleFormData}
                      />
                    </span>{" "}
                  </label>
                </p>
              </div>
            </div>
            <div className="form-block make">
              <div className="form-block-inside">
                <p>
                  <label>
                    {" "}
                    Пояснение (по избор)
                    <br />
                    <span
                      className="wpcf7-form-control-wrap"
                      data-name="your-message"
                    >
                      <textarea
                        cols={40}
                        rows={10}
                        className="wpcf7-form-control wpcf7-textarea"
                        aria-invalid="false"
                        name="additional_info"
                        value={formData.additional_info}
                        onChange={handleFormData}
                      />
                    </span>{" "}
                  </label>
                </p>
              </div>
            </div>
            <button className="submit-button" disabled={loading}>
              {loading ? <BtnLoader /> : "Изпрати"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
