
export default function PersonalDataPolicy() {
    return (
        <div className="cookie-container mx-auto">
            <div className="h1">
                {" "}
                Защита на личните данни. Конфиденциална политика на apolocars.bg
            </div>
            <div className="cookie-text">
                Съобразно Закона за защита на личните данни, Потребителят има
                право на достъп до собствените си Лични данни, които е въвел или
                са станали достояние на apolocars.bg при ползване от на Сайта от
                него, както и на поправка на тези Лични данни.
                <br />
                Приемайки настоящите условия, Потребителите, желаещи контакт с
                apolocars.bg, за услуга или продукт, изрично се съгласяват
                предоставените от тях Лични данни да бъдат обработвани от
                apolocars.bg по електронен път при доставка на поръчани стоки
                или услуги и при необходимост от помощ от служител на
                apolocars.bg
                <br />
                Всички доброволно предоставени от Потребителя Лични данни и
                други Данни, които го идентифицират в процеса на ползване на
                Сайта се съхраняват, обработват и използват от apolocars.bg за
                целите на поддръжка на определени функционалности на
                предлаганите услуги и за целите на рекламата на стоки и услуги,
                предлагани във apolocars.bg.
                <br />
                apolocars.bg изрично уведомява Потребителя, че предоставените от
                него Лични данни не се отстъпват на трети лица за рекламни и
                промоционални цели.
                <br />
                apolocars.bg си запазва правото да използва личните данни на
                Потребителите за разкриване идентичността им в случаите, когато
                това е необходимо в изпълнение на закона, юридически процедури
                или за да се спазят настоящите условия.
                <br />
                „ПРОМОСЕЙЛ“ ЕООД осъзнава изключителната важност на
                поверителността, сигурността и защитата на личните данни на
                клиенти и партньори. Чрез въвеждане на стриктни и последователни
                политики и правила ние се стремим да осигурим защита на всички
                наши дейности.
                <br />
                <br />
                <h3>3.1 Общи положения</h3>
                Тази Декларация за поверителност описва как „ПРОМОСЕЙЛ“ ЕООД
                като администратор на лични данни събира, обработва, съхранява,
                предоставя личната информация на посетителите на уеб сайта
                „ПРОМОСЕЙЛ“ ЕООД във връзка с услугите, които предоставя.
                <br />
                <br />
                Тази Декларация за поверителност съдържа информация за
                политиките и практиките на „ПРОМОСЕЙЛ“ ЕООД относно
                поверителността при събирането, обработването, съхранението и
                предоставянето на лични данни, за избора, който можете да
                направите относно начина на събиране и използване на Ваши лични
                данни, включително информация, която се събира чрез Вашата
                активност онлайн и данните, предоставяни на „ПРОМОСЕЙЛ“ ЕООД във
                връзка с назначаване на работа. бисквитки.
                <br />
                <br />
                Тази Декларация за поверителност не важи за приложения,
                продукти, услуги, уеб сайтове или социални медии на трети
                страни, до които се осъществява достъп чрез връзки, предоставяни
                от услугите на сайта apolocars.bg за Ваше удобство и информация.
                Нашият съвет е да се запознаете с политиката на конфиденциалност
                на всеки сайт, с който взаимодействате, преди да позволите
                събиране и използване на личните Ви данни.
                <br />
                <h3>3.2 Вашето съгласие</h3>
                Тази Декларация за поверителност е част от Общите условия за
                ползване на apolocars.bg. Чрез използването на apolocars.bg Вие
                се съгласявате със събирането, обработването, съхраняването и
                предоставянето на Вашите лични данни в съответствие с тази
                Декларация за поверителност.
                <br />
                <h3>3.3 Дефиниции</h3>
                Лични данни – “Всяка информация, свързана с физическо или
                юридическо лице, което може да бъде идентифицирано пряко или
                непряко, чрез нея, по-специално чрез идентификатори като име,
                идентификационен номер, данни за местонахождение, онлайн
                идентификатор, по един или повече от тези признаци, специфични
                за физическата, психическата, умствената, икономическата или
                културната идентичност на лицето.”
                <br />
                Субект на данни е физическо лице, което е идентифицирано или
                което може да бъде идентифицирано въз основа на определена
                информация.
                <br />
                <h3>3.4 Лични данни, събирани чрез apolocars.bg</h3>
                Не е необходимо да предоставяте лични данни, за да използвате
                apolocars.bg. Но уеб сайтът apolocars.bg може да събира
                предоставени конкретно и доброволно от посетителите лични данни.
                apolocars.bg събира Вашите лични данни, като спазва принципа на
                законосъобразност и прозрачност. Предприемаме разумни действия,
                за да гарантираме, че личните данни са точни, пълни и актуални.
                apolocars.bg събира следните лични данни които Вие ни
                предоставяте, докато използвате apolocars.bg:
                <ul>
                    <li>име;</li>
                    <li>адрес;</li>
                    <li>телефонен номер за контакт;</li>{" "}
                    <li>електронна поща;</li>{" "}
                    <li>други лични данни, предоставени от вас;</li>
                    <br />
                    За фирми – търговци и юридически лица – нетърговци:
                    <li>име;</li>
                    <li>адрес;</li>
                    <li>телефонен номер за контакт;</li>{" "}
                    <li>електронна поща;</li>
                    <li>адресна регистрация;</li>
                    <li>ЕИК;</li>
                    <li>БУЛСТАТ;</li> <li>идентификационен номер по ЗДДС;</li>
                    <li>други лични данни, предоставени от вас;</li>
                </ul>
                <br />
                apolocars.bg не събира „чувствителни“ лични данни, които
                разкриват расов или етнически произход, политически, религиозни
                или философски убеждения; членство в политически партии или
                организации, сдружения с религиозни, философски, политически или
                синдикални цели; които се отнасят до здравето или сексуалния
                живот. <br />
                <br />
                Нелични данни и „бисквитки“, събирани чрез apolocars.bg
                <br />
                Ние събираме и информация, която не подлежи на идентифициране,
                но в определен етап може да бъде свързана с Вашите лични данни –
                това може да бъде Ваше потребителско име, IP адрес, географски
                данни, тип на операционна система, език, парола, предпочитания
                за търсене, свързани с конкретни търсения, както и други общи
                данни. За да гарантираме по-добра навигация, ние или нашите
                доставчици на услугата използваме „бисквитки“ (cookies) за
                събиране на обобщени данни. Ние не събираме и не съхраняваме
                индивидуални „бисквитки“. Имаме достъп само до обобщена
                информация за „бисквитки“ за функционални цели.
                <br />
                <br />
                Можем да споделяме с трети страни информация, която включва:
                <ul>
                    <li>брой посещения на apolocars.bg;</li>
                    <li>брой посетители на всяка страница от apolocars.bg;</li>
                    <li>
                        имената на домейните на доставчиците на интернет услуги
                        на посетителите на apolocars.bg;
                    </li>
                </ul>
                <br />
                <h3>3.5 Право за промяна на лични данни</h3>
                Вие имате право на избор за типа лични данни, които събираме,
                също така и за целите, за които ги събираме и обработваме. Ние
                Ви предоставяме възможност за коригиране, допълване или
                изтриване на лични данни, които сте споделили с нас, като трябва
                да ни уведомите за това ваше искане по ел. поща или телефон,
                обявени в тази декларация.
                <br />
                <br />
                <h3>3.6 Цели, за които използваме Вашите лични данни</h3>
                apolocars.bg използва Вашите лични данни само за целите,
                посочени към момента на събиране след Вашето изрично съгласие,
                или за допълнителни съвместими цели съобразно закона.
                <br />
                Целите, с които apolocars.bg събира и обработва лични данни, са:
                <ul>
                    <li>за да отговорим на Ваши запитвания;</li>
                    <li>
                        за да предоставим продукти, информация и услуги, които
                        поискате;
                    </li>
                    <li>за да подобрим качеството на нашите услуги;</li>
                    <li>
                        за да осигурим ефективно обслужване на нашите
                        посетители;
                    </li>
                    <li>
                        за да се свържем с Вас за специални оферти и информация,
                        за която мислим, че ще представлява интерес за Вас;
                    </li>
                    <li>
                        за да Ви предоставим полезни съвети, информация,
                        актуализации;
                    </li>
                    <li>
                        за да се свържем с Вас с информация относно ползването
                        на сайта apolocars.bg;
                    </li>
                    <li>
                        за да подобрим съдържанието и функционалността на
                        apolocars.bg;
                    </li>
                    <li>
                        за да търсим Вашето мнение за наши продукти и услуги;
                    </li>
                    <li>
                        за целите на маркетинговата и рекламната ни дейност;
                    </li>
                    <li>
                        за всяка друга цел в съгласие с тази декларация за
                        поверителност или за която сме получили отделно Вашето
                        съгласие.
                    </li>
                </ul>
                <br />
                <h3>3.7 Политики и практики за защита на личните данни</h3>
                За да защитим личните данни от неупълномощени за употербата им
                лица, разкриване, промяна или унищожаване, ние прилагаме строги
                стандарти за технологична и оперативна сигурност за защита на
                информацията в собствената си дейност. „ПРОМОСЕЙЛ“ ЕООД взима
                правни и технически мерки за защита на Вашите лични данни в
                съответствие с Регламента за защита на личните данни. <br />
                <br />
                <h3>3.8 Срокове на съхранение на лични данни</h3>
                apolocars.bg съхранява Вашите лични данни толкова дълго, колкото
                е необходимо за обработка на кореспонденцията и всички
                евентуални допълнителни действия <br />
                <h3>3.9 Предоставяне на лични данни на трети страни</h3>
                Осъзнаваме отговорността, която носим, при пренос на лични данни
                към трети страни. Споделяме лични данни само при условие, че
                третите страни имат задължението да осигурят адекватни нива на
                защита. Ние им забраняваме да използват информацията, свързана с
                личните данни за техни собствени цели или за техни собствени
                маркетингови дейности. apolocars.bg си запазва правото да
                разкрива лична информация за Вас, когато вярваме, че законът го
                изисква или в отговор на правоприлагащи органи във връзка с
                наказателно или административно разследване.
                <br />
                <h3>
                    3.10 Защита на личната неприкосновеност на деца и
                    непълнолетни
                </h3>
                Ние разбираме важността от защита на личната неприкосновеност на
                децата в интерактивния онлайн свят. Сайтът apolocars.bg не е
                предназначен за или умишлено насочен към деца на и под
                18-годишна възраст. Ние нямаме политика да събираме умишлено или
                да пазим информация за лица под 18-годишна възраст.
                <br />
                <h3>
                    3.11 Възможни промени по тази Декларация за поверителност
                </h3>
                Възможно е „ПРОМОСЕЙЛ“ ЕООД да направи промени в тази Декларация
                за поверителност във връзка с промени в закона, както и при
                промени в начина на събирането и използването на Вашите лични
                данни. Ако направим промени, те ще бъдат отразени в тази
                Декларация за поверителност и датата на тези промени ще бъде
                упомената в края на документа. За тази цел трябва да преглеждате
                Декларацията за поверителност, за да сте информирани за
                актуалните практики. Ако използвате сайта след датата на влизане
                на измененията, ще се приема, че сте съгласни с всички изменения
                в тази Декларация за поверителност. Ако не сте съгласни с някое
                от измененията, прекратете използването на сайта.
                <br />
                <h3>3.12 Връзка с нас</h3>
                В случай че имате въпроси, свързани с Декларация за
                поверителност, събирането и обработването на Вашите лични данни
                по време на използването на apolocars.bg, можете да се обърнете
                към a.atanasov@apolocars.bg.
                <br />
                <h3>4. Поведение на потребителите</h3>
                Потребителите на Сайта разбират, че информацията, данните,
                публикуваните мнения, текст, софтуер, музика, звук, фотоси или
                всякакви други материали (наречени общо по-нататък
                „Съдържание“), които стават публично достояние или се предават
                конфиденциално, са отговорност единствено на човека, който
                генерира това Съдържание.
                <br />
                Потребителят, ползващ Сайта е изцяло отговорен за публикуваното
                или предавано по друг начин, предоставено от Сайта Съдържание.
                apolocars.bg не гарантира точността, цялостта, верността или
                характера на изпращано и разпространявано, чрез Сайта
                Съдържание.
                <br />
                Използвайки Сайта, Потребителят може да срещне Съдържание, което
                има обиден, неприличен или неприемлив характер. При никакви
                обстоятелства apolocars.bg няма да отговаря за каквото и да е
                Съдържание, създадено от Потребителите, включително за грешки и
                пропуски в него и/или за каквито и да са щети и вреди от
                всякакъв характер, произтекли в резултат на използване на
                Съдържанието, изложено, публикувано, изпратено или предадено по
                някакъв друг начин, чрез Сайта.
                <br />
                <br />
                <h3>
                    Потребителят се съгласява да НЕ използва Сайта на
                    apolocars.bg, за да:
                </h3>
                <ul>
                    <li>
                        формулира, изпраща или предава по някакъв друг начин
                        Съдържание, което е незаконно, вредно, заплашващо,
                        обиждащо, предизвикващо безпокойство, нараняващо,
                        дискредитиращо, вулгарно, неприлично, клеветящо,
                        нарушаващо конфиденциалността на другия или неприемливо
                        от гледна точка на расови, етнически или други причини;
                    </li>
                    <li>
                        създава рекламно съдържание на стоки и услуги извън
                        Сайта, както и да публикува интернет адреси, сочещи към
                        страници и услуги извън Сайта, с изключение на местата
                        където това е изрично посочено като разрешено.
                    </li>
                    <li>причинява вреда на децата по какъвто и да е начин;</li>
                    <li>
                        се представя за човек или група хора, включително и, но
                        не само за: представител на apolocars.bg, представител
                        на производител и др., както и по друг начин да
                        заблуждава относно принадлежността си към група хора или
                        отделен човек;
                    </li>
                    <li>
                        фалшифицира хедъри или по друг начин да манипулира
                        идентификационните средства с цел да се завоалира
                        произходът на Съдържание, предавано чрез Сайта;
                    </li>
                    <li>
                        да формулира, изпраща, публикува или да предава по друг
                        начин Съдържание, което няма право да предава по закон
                        или в резултат на други договорни или поверителни
                        отношения (като вътрешна информация, конфиденциална
                        информация или информация относно нечия собственост,
                        получена в резултат на наемни отношения, или която е
                        предмет на споразумения за неразгласяване);
                    </li>
                    <li>
                        да формулира, изпраща, публикува или да предава по друг
                        начин Съдържание, което нарушава каквото и да е
                        патентно, търговско, авторско или друго право на
                        собственост („Права“) на която и да е страна;
                    </li>
                    <li>
                        да формулира, изпраща, публикува или да предава по друг
                        начин неразрешени рекламни материали, junk mail, spam,
                        „верижни писма“, пренасочени с alias поддомейни,
                        „пирамидални схеми“ или други форми за привличане на
                        клиенти;
                    </li>
                    <li>
                        да формулира, изпраща, публикува или да предава по друг
                        начин материали, съдържащи компютърни вируси или други
                        компютърни кодове, файлове или програми, предназначени
                        да прекъсват, разстройват или ограничават
                        функционирането на компютърен софтуер, хардуер или
                        телекомуникационно оборудване;
                    </li>
                    <li>
                        да нарушава нормалната работа на другите ползватели на
                        Сайта, включително и ако не го ползва, да въздейства по
                        какъвто и да е друг начин, който влияе отрицателно върху
                        възможността на другите ползватели да участват в обмен в
                        реално време;
                    </li>
                    <li>
                        да пречи за предоставяне на услугите на Сайта или
                        нарушава работата на сървъри или мрежи, свързани с
                        услугите, да не се спазват изискванията, процедурите,
                        политиката или разпоредбите на мрежите, свързани с
                        Сайта;
                    </li>
                    <li>
                        да нарушава преднамерено или непреднамерено местни,
                        национални или международни закони;
                    </li>
                    <li>
                        да преследва или по друг начин да безпокои друг
                        Потребител;
                    </li>
                    <li>
                        да събира и използва неправомерно персонална информация
                        за други потребители.
                    </li>
                </ul>
                <br />
                apolocars.bg си запазва правото да ограничава и/или отказва
                достъпа до Сайта на потребители, които еднократно или системно
                нарушават изброените в точка 5 изисквания.
                <br />
                apolocars.bg си запазва правото да редактира и премахва
                информация предоставена от потребителя в нарушение на изброените
                по-горе в точка 4 изисквания.
                <br />
                <h3>5. Авторски права и ограничения свързани с тях</h3>
                apolocars.bg дава право на Потребителите да ползват всички
                услуги, които са предоставени в Сайта само за лични нужди с
                нетърговска цел, при условие че не се нарушават авторските права
                на apolocars.bg или на трети лица свързани пряко или косвено с
                материалите на Сайта. Не се разрешава материалите на този Сайта
                да се променят по никакъв начин, нито да бъдат копирани,
                публично разпространявани или раздавани за каквато и да било
                обществена или търговска цел. Употребата на публикуваните в този
                интернет Сайт материали в други сайтове е забранена.
                <br />
                Материалите на този Сайт са под закрилата на закона за
                Авторското право и сродните му права и всяка непозволена
                употреба може да бъде нарушение на авторско право.
                <br />
                apolocars.bg си запазва правото да преотстъпва правата за
                публикуване на материали и всякакви части от информацията на
                Сайта на трети лица при допълнително сключен договор, уреждащ
                правата и задълженията, в писмена форма между apolocars.bg и
                лицето публикуващо информацията.
                <br />
                <h3>6. Препратки от и към apolocars.bg</h3>
                Собствениците на сайтове и страници извън apolocars.bg имат
                право да създават препратки към всяка една HTML страница на
                apolocars.bg при следните условия:
                <ul>
                    <li>
                        препратката може да сочи към дадената страница, но не и
                        да копира съдържанието й, разрешава се копирането на
                        текстов материал от страницата, към която сочи
                        препратката, с дължина не повече от 10 думи;
                    </li>
                    <li>
                        не трябва да отваря страницата от apolocars.bg в рамки
                        (frames) и да допълва или променя информацията от
                        страницата по какъвто и да е начин. В това число се
                        забранява добавянето на информация преди, след и/или
                        около страницата на apolocars.bg;
                    </li>
                    <li>
                        не трябва да посочва явно или неявно, че apolocars.bg
                        препоръчва него и/или продуктите и услугите му;
                    </li>
                    <li>
                        не трябва да дава невярна или заблуждаваща информация
                        относно продуктите и/или услугите на apolocars.bg;
                    </li>
                </ul>
                Със създаването на препратка към apolocars.bg собственика на
                сайта декларира, че приема настоящите общи условия, дори и да не
                ползва услугите на apolocars.bg;
                <br />
                Собствениците на сайтове и страници извън apolocars.bg нямат
                право да създават препратки към изображения, мултимедийни мостри
                и др. съдържание, а само към HTML страниците предоставящи това
                съдържание.
                <br />
                Предоставените препратки на Сайта на apolocars.bg към сайтове,
                собственост на трети лица са публикувани единствено и само за
                Удобство на потребителите. При употреба на такава препратка
                потребителите не използват услуга, предоставена от apolocars.bg
                и след използването на препратката настоящите Общи условия губят
                сила.
                <br />
                apolocars.bg не контролира тези сайтове и не поема никаква
                отговорност за никой от тях и/или тяхното съдържание. По този
                начин apolocars.bg по никакъв начин не налага и не препоръчва
                тези сайтове на потребители, нито информацията публикувана в
                тях. Посещението и всички рискове, свързани с него, на такъв
                сайт е изцяло отговорност на потребителя.
                <br />
                <h3>7. Представяни услуги и продукти на Сайта</h3>
                В страницата на всяка услуга или продукт представена в Сайта е
                посочено описание. apolocars.bg не носи отговорност за
                неточности в описанието на услугата/продукта и не претендира за
                изчерпателност на предоставената информация.
                <br />
                <h3>8. Надзорни органи</h3>
                Koмиcия зa зaщитa нa личнитe дaнни
                <br />
                Aдpec: гp. Coфия, yл. „Ивaн Eвcтaтиeв Гeшoв“ № 15, <br />
                Телефон: 02 / 940 20 46 <br />
                Факс: 02 / 940 36 40 <br /> Уeб caйт: www.cpdp.bg
                <br />
                <br />
                Koмиcия зa зaщитa нa пoтpeбитeлитe
                <br />
                Aдpec: гp. Coфия, пл. „Cлaвeйкoв“ №4A, eт.3, 4 и 6, <br />
                Телефон: 02 / 980 25 24 <br /> Факс: 02 / 988 42 18 <br />
                Уeб caйт: www.kzp.bg
                <br />
                <br />
                Koмиcия зa зaщитa нa кoнкypeнциятa
                <br />
                Aдpec: гp. Coфия, бyл. „Bитoшa“ №18 <br /> Телефон: 02 / 935 61
                13 <br />
                Факс: 02 / 980 73 15 <br />
                Уeб caйт: www.cpc.bg
            </div>
        </div>
    );
}
