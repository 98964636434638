import "./CookiePolicy.css";

export default function CookiePolicy() {
  return (
    <div className="cookie-container mx-auto">
      <div className="h1">Политика / информация за бисквитки (cookies)</div>
      <div className="cookie-text">
        За да сме сигурни, че получавате най-подходящата информация и
        най-добрите услуги, когато посещавате apolocars.bg, информация и данни
        ще бъдат събирани чрез употреба на т. нар. „бисквитки“ (cookies).
        <br />
        Това ни помага (а също и на други упълномощени трети страни) да Ви
        осигурим персонализирано преживяване, когато посещавате нашия Сайт.
        Освен това ни позволява да подобрим услугите си и да сме сигурни, че
        лесно ще намерите онова, което търсите.
        <br />
        Искаме да разберете начина, по който използваме тези технологии, затова
        и тази Политика за бисквитки обяснява видовете технологии, които
        използваме, какво правят те и Вашия избор по отношение на тяхната
        употреба.
        <br />
        <br />
        Какво представляват бисквитките?
        <br />
        Бисквитките са малки частици данни, които се изпращат до Вашия браузър
        от уеб сървър и се съхраняват на Вашето устройство, така че сайтът да
        може да разпознае Вашия компютър.
        <br />
        <br />
        Има два вида бисквитки – постоянни и временни или „сесийни“ бисквитки.
        <br />
        <br />
        Постоянните бисквитки се съхраняват като файл на Вашия компютър или
        мобилно устройство за дълъг период от време.
        <br />
        <br />
        Сесийните бисквитки се поставят временно в компютъра Ви, когато
        посещавате нашия Сайт, но изчезват, когато затворите страницата, което
        означава, че те не се съхраняват за постоянно на Вашето устройство.
        <br />
        <br />
        Повечето компании използват бисквитки на сайтовете си, за да подобрят
        използваемостта. Бисквитките не могат да навредят на Вашите файлове или
        да увеличат риска от вируси в компютъра Ви.
        <br />
        <br />
        Ние и нашите доставчици на услуги можем да използваме следните видове
        бисквитки:
        <br />
        Задължителни бисквитки (essential cookies).
        <br />
        Тези бисквитки са строго необходими, за да може нашият сайт да изпълнява
        своите функции. Използваме тези бисквитки например:
        <ul>
          <li>
            за да установим автентичността и самоличността на нашите
            потребители, когато те използват нашия Сайт, така че да можем да
            предоставим нашите услуги;
          </li>
          <li>
            {" "}
            за да можем да изпълняваме нашите Общи условия и да поддържаме
            сигурността на нашите услуги.
          </li>
        </ul>
        Бисквитки за ефективност и функционалност (Performance and functionality
        cookies):
        <br />
        <br />
        Тези бисквитки не са строго необходими, но ни позволяват да
        персонализираме Вашето онлайн преживяване в нашата Интернет страница.
        Използваме тези бисквитки например:
        <ul>
          <li>
            за да запомним Вашите предпочитания, така че да не е нужно всеки път
            да вкарвате информация, която вече сте предоставили /например,
            когато въвеждате данните си за достъп до нашите услуги или когато
            използвате форма за запитване/;
          </li>
          <li>
            за да събираме информация за начина, по който нашите потребители
            използват услугите ни, така че да можем да подобрим нашия Сайт и
            нашите Услуги и да извършваме пазарни проучвания (например:
            информация за популярни страници, навици на гледане, брой на
            кликовете, брой на споделянията, харесвания за дадена страница,
            последни посетени страници, и др.
          </li>
        </ul>
        <br />
        Рекламни бисквитки.
        <br />
        Използваме тези бисквитки например:
        <ul>
          <li>
            за да получим информация за начина, по който използвате нашата
            Интернет страница, като например страници, които посещавате или как
            реагирате на реклами, за да Ви предоставим реклами, които са
            съобразени с Вас;
          </li>
          <li>за да определим кои са най-популярните части от нашия сайт;</li>
          <li>
            за да следим използването на нашите услуги и нашия сайт (честота и
            време);
          </li>
          <li>за да следим успеха на даден продукт/услуга;</li>
          <li>
            за да определим колко често Вие и останалите потребители посещавате
            нашия сайт;
          </li>
          <li>
            за да извършваме анкети, така че взаимодействието Ви с нашият Сайт и
            нас самите да може да бъде подобрено.
          </li>
        </ul>
        Често тези бисквитки целят предоставянето на реклами, които са
        съобразени с Вас – както в нашия Сайт, така и извън него. Този тип
        реклами са известни като „реклама, базирана на интересите“. Много от
        тези видове бисквитки принадлежат на нашите доставчици на услуги.
        <br />
        <br />
        Сесийни бисквитки.
        <br />
        <br />
        Възможно е да използваме и сесийни бисквитки, например:
        <ul>
          <li>
            за да Ви позволим да се движите между отделните страници на нашия
            Сайт. Тази практика е известна като „един вход“ (single sign-on);
          </li>
          <li>
            за да Ви разпознаваме, когато се връщате на нашия Сайт, за да
            използвате услугите ни;
          </li>
        </ul>
        <br />
        Как ние използваме бисквитките?
        <br />
        Ние използваме информацията от бисквитките, за да направим нашата
        Интернет страница по-приятна и лесна за използване от потребителите и за
        да можем да Ви осигурим персонализирани препоръки.
        <br />
        <br />
        Бисквитки на трети страни:
        <br />
        Използваме и някои бисквитки на трети страни като част от нашите услуги.
        Тези бисквитки се управляват от съответните сайтове и не се контролират
        от нас. По-долу са изброени бисквитките на трети страни, които
        използваме, някои от които могат да бъдат изключени с помощта на общите
        настройки на Вашия браузър. За други е необходимо да посетите
        съответните сайтове и да следвате осигурените инструкции.
        <ul>
          <li>
            Google Analytics – за да генерираме статистика за трафика на сайта и
            източниците на трафик.
          </li>
          <li>Социални мрежи Facebook, Google plus и др..</li>
          <li>Системи за комуникация.</li>
          <li>Системи за изпращане на електронна поща.</li>
          <li>Системи за сервиране на реклами.</li>
        </ul>
        <br />
        Как се изтриват бисквитки?
        <br />
        Можете сами да изберете дали да приемете бисквитките или не. Ако искате
        да знаете кога Вашият компютър получава бисквитка, можете да настроите
        браузъра си така, че той да Ви уведомява за това. Така имате възможност
        да приемете или откажете дадена бисквитка. Вашият компютър може да бъде
        настроен да отхвърля всички бисквитки. Ако искате да разберете как да
        направите това, посетете aboutcookies.org. Моля, обърнете внимание, че
        промените в браузъра Ви, с които се деактивира функцията за бисквитки,
        ще попречат на части от нашия Сайт да функционират правилно.
        <br />
        <br />
        Повече информация за Вашият браузър и бисквитките:
        <br />
        <a className="cookie-a">Cookie settings in Internet Explorer</a>
        <br />
        <a className="cookie-a">Cookie settings in Firefox</a>
        <br />
        <a className="cookie-a">Cookie settings in Chrome</a>
        <br />
        <a
          className="cookie-a"
          href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
          target="blank"
        >
          Cookie settings in Safari web
        </a>
        <br />
        <a
          className="cookie-a"
          href="https://support.apple.com/en-us/105082"
          target="blank"
        >
          iOS
        </a>
        <br />
        <br />
        Промени в нашата политика за бисквитките. Всички бъдещи промени в нашата
        Политика за бисквитките ще бъдат публикувани на тази страница. При
        необходимост, ще Ви уведомяваме за евентуални промени по имейл в
        съответствие с Общите условия.
        <br />
        <br />
        apolocars.bg не носи отговорност, ако потребителят не е прочел последния
        вариант на настоящите условия.
        <br />
        <br />
        Орган за алтернативно решаване на спорове по смисъла на чл. 181н, ал. 4
        ЗЗП са помирителните комисии към Комисия за защита на потребителите.
        <br />
        <br />
        За контакти
        <br />С радост приемаме всякакви въпроси, коментари и искания, свързани
        с тази Политика за бисквитките. Изпращайте ги на
        a.atanasov@apolocars.bg.
      </div>
    </div>
  );
}
