import "./BtnLoader.css";

export default function BtnLoader() {

    return (
        <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}
