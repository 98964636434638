import promosale from "../../assets/promosale.jpg";
import "./Home.css";
import promosaleHome from "../../assets/promosaleHome.png";
import { Link } from "react-router-dom";
import car from "../../assets/car.jpg";

export default function Home() {
  return (
      <>
          <div className="container mx-auto hero-img-cont">
              <img alt="Promosale" src={promosale} className="img-home" />
              <div className="hero-action">
                  <h1>Отношение, доверие, сигурност</h1>
                  <Link to="/za-nas">За нас</Link>
              </div>
          </div>
          <div className="home-section">
              <div className="grid-container">
                  <div className="logo-span" />

                  <div className="grid-item car-1">
                      <Link to="/nalichni-avtomobili">Налични автомобили</Link>
                  </div>
                  <div className="row-span-2 grid-item car-2">
                      <Link to="/zayavka-za-avtomobil">Поръчай автомобил</Link>
                  </div>
                  <div className="grid-item car-3">
                      <Link to="/kontakti">Контакти</Link>
                  </div>
                  <div className="info-span">
                      <div style={{ color: "#fff" }}>
                          <h2>С какво се занимаваме?</h2>
                          <p>&nbsp;</p>
                          <ul>
                              <li>
                                  <h4>Нов внос на автомобили от Германия​</h4>
                              </li>
                              <li>
                                  <h4>
                                      Доставка по заявка на всички видове МПС и
                                      консултация
                                  </h4>
                              </li>
                              <li>
                                  <h4>
                                      Транспорт на автомобил по поръчка, внос от
                                      чужбина
                                  </h4>
                              </li>
                              <li>
                                  <h4>
                                      Налични автомобили на склад в Германия​
                                  </h4>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}
