import "./Footer.css";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
export default function Footer() {
  return (
      <footer>
          <div className="footer-container row">
                  <div className="footer-section logo-section col-lg-3">
                      <img
                          alt="Promosale"
                          src={logo}
                          className="footer-img-responsive"
                      />
                  </div>
                  <div className="footer-section address-section col-lg-3">
                      <h4 className="footer-h">адрес</h4>
                      <p>
                          <a
                              href="https://www.google.com/maps/place/Promosale+Plovdiv/@42.191783,24.7360175,15z/data=!4m6!3m5!1s0x14acd359c5dd91d7:0x6c0fd7e4819ff86f!8m2!3d42.191783!4d24.7360175!16s%2Fg%2F11sd2_z827"
                              target="blank"
                          >
                              гр. Пловдив ул.Васил Левски 205
                          </a>
                      </p>
                  </div>
                  <div className="footer-section phone-section col-lg-3">
                      <h4 className="footer-h">телефон</h4>
                      <p>
                          <a href="tel:0884 27 77 76">0884 27 77 76</a>
                      </p>
                      <p>
                          <a href="tel:0879 87 00 00">0879 87 00 00</a>
                      </p>
                  </div>
                  <div className="footer-section email-section col-lg-3">
                      <h4 className="footer-h">имейл</h4>
                      <p>
                          <a href="mailto:promosaleplovdivsm@gmail.com">
                              promosaleplovdivsm@gmail.com
                          </a>
                      </p>
                      <p>
                          <a href="mailto:promosaleplovdiv@abv.bg">
                              promosaleplovdiv@abv.bg
                          </a>
                      </p>
                  </div>
          </div>
          <div className="after-footer">
              <NavLink to="/usloviya-za-polzvane" className="footer-a">
                  условия за ползване
              </NavLink>
              <NavLink to="/politika-za-biskvitki" className="footer-a">
                  политика за бисквитките
              </NavLink>
              <NavLink to="/politika-za-lichnite-danni" className="footer-a">
                  политика за личните данни
              </NavLink>
              <div className="footer-a">PROMOSALEPLOVDIV.BG © 2023.</div>
              <div className="footer-a">
                  {" "}
                  УЕБ ДИЗАЙН И РАЗРАБОТКА SIWEB STUDIO
              </div>
              <div className="footer-a">
                  <NavLink
                      to="https://www.facebook.com/people/Promosale-Plovdiv/100088837076351/"
                      target="blank"
                  >
                      <FaFacebookSquare size={30} />
                  </NavLink>
                  <NavLink
                      to="https://www.instagram.com/promosale_plovdiv/"
                      target="blank"
                  >
                      <FaSquareInstagram size={30} />
                  </NavLink>
              </div>
          </div>
      </footer>
  );
}
