import { useEffect, useState } from "react";
import BreadCrump from "../../components/BreadCrump/BreadCrump";
import Loader from "../../components/utils/Loader/Loader";
import BtnLoader from "../../components/utils/BtnLoader/BtnLoader";
import "./CarRequest.css";

import * as baseService from "../../services/BaseService";
import { toast } from "react-toastify";

export default function CarRequest() {
  const [requestData, setRequestData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState({
    is_info_correct: false,
    is_policy_accepted: false,
    is_info_processed: false,
  });
  const [formData, setFormData] = useState({
    make: "",
    model: "",
    first_registration_date: "",
    price_from: "",
    price_to: "",
    milage: "",
    type: "",
    engine: "",
    transmission: "",
    name: "",
    email: "",
    additional_info: "",
    phone: "",
    location: "",
  });
  const [requestDataModels, setRequestDataModels] = useState(null);

  // Fetches initial data for form fields when the component mounts
  useEffect(() => {
    baseService.getRequestData().then((result) => {
      if (result.success) {
        setRequestData(result.fields);
      } else {
        console.log(result.message);
      }
    });
  }, []);

  // Display loader while waiting for requestData to be fetched
  if (!requestData) {
    return <Loader />;
  }

  // Function to handle changes to form input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // If the "make" field is changed, update the models for that make
    if (name == "make") {
      const marka = requestData.marka.find((marka) => marka.id == value);
      handleMakeChange(marka.label);
    }
  };

  // Handles changes to the checkboxes
  const handleChangeCheckBoxes = (e) => {
    const { name, checked } = e.target;
    setCheckBoxes({
      ...checkBoxes,
      [name]: checked,
    });
  };

    // Fetches models for the selected make
  const handleMakeChange = (make) => {
    baseService.getRequestModelsData(make).then((result) => {
      if (result.success) {
        setRequestDataModels(result.models);
      } else {
        console.log(result.message);
      }
    });
  };

    // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all checkboxes are selected
    const allSelected = Object.values(checkBoxes).every((value) => value);
    if (!allSelected) {
      toast.error("Моля, потвърдете че сте съгласни с условията");
      return;
    }

    // Set loading state and submit the form data
    setLoading(true);
    baseService
      .carRequest(formData)
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          // Reset states on success
          setCheckBoxes({
            is_info_correct: false,
            is_policy_accepted: false,
            is_info_processed: false,
          });
          setFormData({
            make: "",
            model: "",
            first_registration_date: "",
            price_from: "",
            price_to: "",
            milage: "",
            type: "",
            engine: "",
            transmission: "",
            name: "",
            email: "",
            additional_info: "",
            phone: "",
            location: "",
          });
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <BreadCrump title="Заявка за автомобил" />
      <section>
        <div className="container mx-auto car-request">
          <div className="container">
            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="form-groups">
                  <div className="form-group">
                    <h4>
                      <strong>Информация за автомобил</strong>
                    </h4>
                    <div className="form-group-item">
                      <label htmlFor="make">Марка</label>
                      <select
                        name="make"
                        id="make"
                        required
                        onChange={handleChange}
                        value={formData.make}
                      >
                        <option value="">Избери</option>
                        {requestData?.marka.map((marka) => (
                          <option value={marka.id} key={marka.id}>
                            {marka.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="model">Модел</label>
                      <select
                        name="model"
                        id="model"
                        required
                        onChange={handleChange}
                        value={formData.model}
                      >
                        <option value="">Избери</option>
                        {requestDataModels &&
                          requestDataModels.map((model) => (
                            <option value={model.id} key={model.id}>
                              {model.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="model">Дата на първа регистрация</label>
                      <select
                        name="first_registration_date"
                        id="model"
                        required
                        onChange={handleChange}
                        value={formData.first_registration_date}
                      >
                        <option value="">Избери</option>
                        {requestData?.year.map((year) => (
                          <option value={year.id} key={year.id}>
                            {year.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-block-single">
                      <div className="form-block-inside">
                        <label>Цена от:</label>
                        <input
                          name="price_from"
                          onChange={handleChange}
                          value={formData.price_from}
                        />
                      </div>
                      <div className="form-block-inside">
                        <label>Цена до:</label>
                        <input
                          name="price_to"
                          onChange={handleChange}
                          value={formData.price_to}
                        />
                      </div>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="milage">Пробег</label>
                      <input
                        type="text"
                        name="milage"
                        className="form-control"
                        id="milage"
                        onChange={handleChange}
                        value={formData.milage}
                      />
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="model">Тип</label>
                      <select
                        name="type"
                        id="type"
                        required
                        onChange={handleChange}
                        value={formData.type}
                      >
                        <option value="">Избери</option>
                        {requestData?.category.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="model">Двигател</label>
                      <select
                        name="engine"
                        id="engine"
                        required
                        onChange={handleChange}
                        value={formData.engine}
                      >
                        <option value="">Избери</option>
                        {requestData?.engine_type.map((engine_type) => (
                          <option value={engine_type.id} key={engine_type.id}>
                            {engine_type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="model">Трансмисия</label>
                      <select
                        name="transmission"
                        id="transmission"
                        value={formData.transmission}
                        required
                        onChange={handleChange}
                      >
                        <option value="">Избери</option>
                        {requestData?.transmission.map((transmission) => (
                          <option value={transmission.id} key={transmission.id}>
                            {transmission.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="name">Допълнителна Информация</label>
                      <textarea
                        name="additional_info"
                        id=""
                        cols="30"
                        rows="10"
                        value={formData.additional_info}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <h4>
                      <strong>Информация за контакт</strong>
                    </h4>
                    <div className="form-group-item">
                      <label htmlFor="name">Име</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="email">Имейл</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="phone">Телефон</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group-item">
                      <label htmlFor="location">Локация</label>
                      <input
                        type="text"
                        name="location"
                        className="form-control"
                        id="location"
                        value={formData.location}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group-item checkbox-block">
                      <label>
                        <input
                          type="checkbox"
                          name="is_info_correct"
                          required=""
                          onChange={handleChangeCheckBoxes}
                        />{" "}
                        Информацията е точна, очаквам вашата оферта
                      </label>
                    </div>
                    <div className="form-group-item checkbox-block">
                      <label>
                        <input
                          type="checkbox"
                          required=""
                          onChange={handleChangeCheckBoxes}
                          name="is_policy_accepted"
                        />{" "}
                        ПОТВЪРЖДАВАМ, ЧЕ СЪМ ПРОЧЕЛ ПОЛИТИКАТА ЗА ПОВЕРИТЕЛНОСТ
                        И Я ПРИЕМАМ
                      </label>
                    </div>
                    <div className="form-group-item checkbox-block">
                      <label>
                        <input
                          type="checkbox"
                          required=""
                          onChange={handleChangeCheckBoxes}
                          name="is_info_processed"
                        />{" "}
                        СЪГЛАСЕН СЪМ ПРЕДОСТАВЕНАТА ОТ МЕН ИНФОРМАЦИЯ ДА БЪДЕ
                        ОБРАБОТВАНА И ИЗПОЛЗВАНА ЗА ИНФОРМАЦИЯ И ПРЕДЛОЖЕНИЯ
                        ОТНОСНО ПРОДУКТИ И УСЛУГИ
                      </label>
                    </div>
                    <div className="form-block request">
                      <button name="sf-request" value={1} disabled={loading}>
                        {loading ? (
                          <span
                            style={{
                              height: "35px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <BtnLoader />
                          </span>
                        ) : (
                          "Изпращане"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
