import "./SingleCarPage.css";
import SingleCarCarousel from "../../components/SingleCarCarousel/SingleCarCarousel";
import { TfiCheckBox } from "react-icons/tfi";
import BreadCrump from "../../components/BreadCrump/BreadCrump";
import { useEffect, useState } from "react";
import OfferForm from "../../components/OfferForm/OfferForm";

import * as baseService from "../../services/BaseService";
import { useParams } from "react-router-dom";
import Loader from "../../components/utils/Loader/Loader";
export default function SingleCarPage() {
    const [isOpen, setIsOpen] = useState(false);
    const { slug } = useParams();
    const [car, setCar] = useState(null);

    useEffect(() => {
        baseService.loadCar(slug).then((result) => {
            if (result.success) {
                setCar(result.car);
            } else {
                console.log(result.message);
            }
        });
    }, []);

    if (!car) {
        return <Loader />;
    }
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="main-container">
            <BreadCrump
                title={`${car.fields?.marka} ${car.fields?.model} \n ${car.fields?.modification}`}
                car_price={`${car.fields?.price} ${
                    car.fields?.currency == "EUR" ? "€" : car.fields?.currency
                } ${car.vat ? "" : "Без ДДС"}`}
            />
            <SingleCarCarousel images={car?.images} />

            <div className="buttons-section">
                <div className="si-container">
                    <div className="container-number">
                        <span>Референтен номер:</span>
                        <strong> {car?.id}</strong>
                    </div>
                    <div className="container-buttons">
                        <a
                            href="tel:+359884277776"
                            className="si-button offer"
                            datatype="offer"
                        >
                            <i className="fas fa-phone-alt"></i> Обади се
                        </a>
                        <a
                            href="#"
                            className="si-button offer form-sidebar-offer"
                            onClick={handleClick}
                        >
                            <i className="far fa-file-alt"></i> Поискай оферта
                        </a>
                        {isOpen && <OfferForm car={car} />}
                    </div>
                </div>
            </div>
            <div className="si-details-section">
                <div className="si-container">
                    <h2>Детайли</h2>
                    <div className="si-car-data">
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Марка: <span>{car.fields?.marka}</span>
                            </div>
                            <div className="col-md-6">
                                Модел: <span>{car.fields?.model}</span>
                            </div>
                        </div>
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Цвят: <span>{car.fields?.color}</span>
                            </div>
                            <div className="col-md-6">
                                Състояние: <span>{car.fields?.nup}</span>
                            </div>
                        </div>
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Тип: <span>{car.fields?.category}</span>
                            </div>
                            <div className="col-md-6">
                                Двигател: <span>{car.fields?.engine_type}</span>
                            </div>
                        </div>
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Евро: <span>{car.fields?.euroclass}</span>
                            </div>
                            <div className="col-md-6">
                                Обем двигател:{" "}
                                <span>{car.fields?.engine_cubature}</span>
                            </div>
                        </div>
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Мощност: <span>{car.fields?.engine_power}к.с.</span>
                            </div>
                            <div className="col-md-6">
                                Скоростна кутия:{" "}
                                <span>{car.fields?.transmission}</span>
                            </div>
                        </div>
                        <div className="si-car-detail-row row">
                            <div className="col-md-6">
                                Година: <span>{car.fields?.year}</span>
                            </div>
                            <div className="col-md-6">
                                Пробег: <span>{car.fields?.km}</span>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>

            <div className="si-extri-section">
                <div className="si-container">
                    <h2>Екстри</h2>
                    <div className="si-extras-wraper">
                        <div className="si-extras-block row">
                            {car?.extri &&
                                Object.entries(car?.extri).map(
                                    ([title, values]) => {
                                        if (values.label) {
                                            return;
                                        }
                                        return (
                                            <div key={title}>
                                                <div className="si-extras-title col-md-12">
                                                    <h4>{title}</h4>
                                                </div>
                                                <div className="si-extras-block-list col-md-12">
                                                    {values?.map((value, i) => {
                                                        return (
                                                            <div
                                                                className="col-md-4"
                                                                key={i}
                                                            >
                                                                <span>
                                                                    <i>
                                                                        <TfiCheckBox />
                                                                    </i>{" "}
                                                                    {value}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
                {car.extri_dop && car.extri_dop.length > 1 && (
                    <div className="si-container">
                        <h2>Допълнителни екстри</h2>
                        <div className="si-extras-wraper mt-48">
                            <div className="si-extras-block row">
                                <div className="si-extras-block-list col-md-12">
                                    {car.extri_dop.map((value, i) => (
                                        <div className="col-md-4" key={i}>
                                            <span>
                                                <i>
                                                    <TfiCheckBox />
                                                </i>{" "}
                                                {value}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
