import { Link } from "react-router-dom";
import "./NotFound.css";
export default function NotFound() {
    return (
        <div className="error-section">
            <div className="content">
                <h1>404</h1>
                <h2>Страницата не е намерена</h2>
                <Link to="/" className="back-home">
                    {" "}
                    Начало
                </Link>
            </div>
        </div>
    );
}
