import { getCsrfToken } from "../utils/functions";

const baseUrl = process.env.REACT_APP_APP_URL;

export const loadInitCars = async () => {
    try {
        const response = await fetch(`${baseUrl}/load-init-cars`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const loadCars = async () => {
    try {
        const response = await fetch(`${baseUrl}/load-cars`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};

export const loadCar = async (slug) => {
    try {
        const response = await fetch(`${baseUrl}/load-car/${slug}`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};

export const getRequestData = async () => {
    try {
        const response = await fetch(`${baseUrl}/request-data`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const getFilterData = async () => {
    try {
        const response = await fetch(`${baseUrl}/filter-data`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};

export const getRequestModelsData = async (marka) => {
    try {
        const response = await fetch(`${baseUrl}/request-data-models/${marka}`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const getFilterModelsData = async (marka) => {
    try {
        const response = await fetch(`${baseUrl}/filter-data-models/${marka}`);
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const contactRequest = async (requestData) => {
    const csrfToken = getCsrfToken();
    try {
        const response = await fetch(`${baseUrl}/contact-request`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": csrfToken,
            },
            body: JSON.stringify(requestData),
        });

        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const carRequest = async (requestData) => {
    const csrfToken = getCsrfToken();
    try {
        const response = await fetch(`${baseUrl}/car-request`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": csrfToken,
            },
            body: JSON.stringify(requestData),
        });

        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
export const getOffer = async (requestData) => {
    const csrfToken = getCsrfToken();
    try {
        const response = await fetch(`${baseUrl}/get-offer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": csrfToken,
            },
            body: JSON.stringify(requestData),
        });

        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};
