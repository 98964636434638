import "./Terms.css";

export default function Terms() {
  return (
    <div className="terms-container mx-auto">
      <div className="h1">Условия за използване на Сайта на apolocars.bg</div>
      <div className="terms-text">
        Потребителят разбира и приема обстоятелството, че информационните услуги
        и Сайта се предоставят „във вида, в който са“ и това, че apolocars.bg не
        поема никаква отговорност за навременността, изтриването и
        невъзможността да бъде доставена информация относно услуги и продукти на
        Потребителя, негови или на други Потребители мнения и оценки за услуга и
        продукт, както и за нанесени щети, пропуснати ползи и други вреди,
        настъпили след, в резултата на или поради използване (или пък
        невъзможност за използване поради технически проблеми, профилактика,
        решения на администратора и др.) на Сайта. В случай, че в следствие на
        използването на този Сайт или материали от него доведе до възникването
        на вреди, за които е необходим ремонт или поправка на оборудване или
        информация Потребителят поема цялата отговорност и всички разходи,
        свързани с отстраняването на нанесените вреди. За да използва Сайта,
        Потребителят трябва да получи достъп до World Wide Web пряко, или чрез
        други устройства, които имат достъп до Web базирано съдържание. Освен
        това Потребителят трябва да си осигури цялото необходимо за достъп до
        World Wide Web оборудване, включително компютър, модем или другo
        средствo за достъп до Интернет мрежата. apolocars.bg само предоставя
        информацията на Сайта и не отговаря за каквито и да са смущения или
        технически проблеми, осуетяващи ползването на Сайта, вследствие
        експлоатирането на компютърното оборудване от потребителя.
      </div>
    </div>
  );
}
