import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import CarRequest from "./pages/CarRequest/CarRequest";
import AvailableCars from "./pages/AvailableCars/AvailableCars";
import About from "./pages/About/About";
import Contacts from "./pages/Contacts/Contacts";
import Terms from "./pages/Terms/Terms";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import SingleCarPage from "./pages/SingleCarPage/SingleCarPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/utils/ScrollToTop/ScrollToTop";
import PersonalDataPolicy from "./pages/PersonalDataPolicy/PersonalDataPolicy";
import NotFound from "./pages/Error/NotFound";
function App() {
    return (
        <div className="App">
            <NavBar />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/zayavka-za-avtomobil" element={<CarRequest />} />
                <Route path="/nalichni-avtomobili" element={<AvailableCars />} />
                <Route path="/za-nas" element={<About />} />
                <Route path="/kontakti" element={<Contacts />} />
                <Route path="/single-car/:slug" element={<SingleCarPage />} />
                <Route path="/usloviya-za-polzvane" element={<Terms />} />
                <Route
                    path="/politika-za-biskvitki"
                    element={<CookiePolicy />}
                />
                <Route
                    path="/politika-za-lichnite-danni"
                    element={<PersonalDataPolicy />}
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <ToastContainer />
            <Footer />
        </div>
    );
}

export default App;
