import "./SingleCarCarousel.css";
import OwlCarousel from "react-owl-carousel";
import { PhotoProvider, PhotoView, PhotoSlider } from "react-photo-view";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-photo-view/dist/react-photo-view.css";

export default function SingleCarCarousel({ images }) {
   console.log(images);
    return (
      <>
        <div className="carousel-container">
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            responsive={responsiveOptions}
          >
            <PhotoProvider loop={true} children={images}>
              {images.map((image, index) => (
                <div className="item" key={index}>
                  <PhotoView
                    src={`${process.env.REACT_APP_ASSET_URL}/storage/${image}`}
                  >
                    <img
                      alt="Promosale"
                      src={`${process.env.REACT_APP_ASSET_URL}/storage/${image}`}
                      className="img-details"
                    />
                  </PhotoView>
                </div>
              ))}
            </PhotoProvider>
          </OwlCarousel>
        </div>
      </>
    );
}

const responsiveOptions = {
    0: {
        items: 1,
    },
    600: {
        items: 2, // Number of items to show on medium screens (600px and up)
    },
    1000: {
        items: 3, // Number of items to show on large screens (1000px and up)
    },
};
