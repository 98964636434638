import "./About.css";
import about from "../../assets/about.jpg";
import aboutLogo from "../../assets/aboutLogo.png";
import BreadCrump from "../../components/BreadCrump/BreadCrump";

export default function About() {
  return (
    <>
    <BreadCrump title="За нас" />
      <div className="container mx-auto about-img-cont">
        <img alt="Promosale" src={about} className="img-about" />
      </div>
      <div className="about-container ">
        <div className="text">
          <h2 className="about-h">Кои сме ние?</h2>
          <p className="about-p">Предмет на дейност</p>
          <ul>
            <li>Нов внос на автомобили от Германия</li>
            <li>Доставка по заявка на всички видове МПС и консултация</li>
            <li>Транспорт на автомобил по поръчка, внос от чужбина</li>
            <li>Налични автомобили на склад в Германия</li>
            <li>Собствен транспорт и превоз</li>
            <li>Възможност за изготвяне на индивидуални оферти за клиенти</li>
            <li>Възможност за финансов, оперативен лизинг и наем</li>
            <li>
              Продажба на оригинални авточасти, втора употреба и нови, масла и
              консумативи
            </li>
            <li>Продажба на автомобилни и товарни гуми</li>
          </ul>
        </div>
        <div className="image-cont">
          <img alt="Promosale" src={aboutLogo} className="logo-about" />
          <p className="image-text">
            Условията за покупка на автомобили при нас са изключително гъвкави,
            като основната идея е да предоставим на клиентите си възможно
            най-много възможности, подходящи за всяко едно индивидуално търсене.
          </p>
        </div>
      </div>
    </>
  );
}
