import "./CarCard.css";
// import car from "../../assets/car.jpg";
import { FaCarSide, FaRegCalendarAlt, FaRoad } from "react-icons/fa";
import { BsFuelPumpFill } from "react-icons/bs";
import { GiGears } from "react-icons/gi";
import { IoIosSpeedometer } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import OfferForm from "../OfferForm/OfferForm";

export default function CarCard({ car }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Effect to handle body scroll behavior when OfferForm is open
  useEffect(() => {
    const handleBodyOverflow = () => {
      if (isOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    };

    handleBodyOverflow();

    // Cleanup effect to ensure no-scroll class is removed on unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  // Toggle the visibility of OfferForm
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Navigate to detailed car view and save scroll position
  const handleNavigation = (slug) => {
    sessionStorage.setItem("scrollPosition", window.scrollY);
    setTimeout(() => {
      navigate(`/single-car/${slug}`);
    }, 100);
  };

  // Sanitize numeric input to remove non-digit characters
  const sanitizeNumeric = (input) => {
    return input.replace(/\D/g, "");
  };
  // Sanitize mileage value
  const sanitizedMileage = sanitizeNumeric(String(car.mileage));
  return (
    <>
      <div className="v-card">
        <div className="tab-content">
          <div className="tab-pane fade active in" id="home">
            <div className="vcard-img">
              <img
                src={`${process.env.REACT_APP_ASSET_URL}/storage/${car?.image}`}
                alt=""
                className="img-responsive img-thumbnail cursor-pointer"
                onClick={() => handleNavigation(car.slug)}
              />
            </div>
            <div className="vcard-content">
              <div>
                <button
                  className="price-button"
                  onClick={() => handleNavigation(car.slug)}
                >
                  {`${car.price} ${
                    car.currency?.value == "EUR" ? "€" : car.currency?.value
                  } `}
                  {car.vat ? "" : "без ДДС"}
                </button>
                <h4
                  className="heading"
                  onClick={() => handleNavigation(car.slug)}
                >
                  {car.name}
                  <br />
                  <br />
                </h4>
              </div>

              <div className="inner-content">
                <div className="first-col">
                  <div>
                    <FaRegCalendarAlt
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Година
                  </div>
                  <span className="add-info">{car.year?.value}</span>
                </div>
                <div className="second-col">
                  <div>
                    <FaRoad
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Пробег
                  </div>{" "}
                  <span className="add-info">{sanitizedMileage} км</span>
                </div>
                <div className="first-col">
                  <div>
                    <BsFuelPumpFill
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Двигател
                  </div>{" "}
                  <span className="add-info">{car?.engine_type?.value}</span>
                </div>
                <div className="second-col">
                  <div>
                    <FaCarSide
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Тип
                  </div>{" "}
                  <span className="add-info">{car?.car_type?.value}</span>
                </div>
                <div className="first-col">
                  <div>
                    <GiGears
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Трансмисия
                  </div>{" "}
                  <span className="add-info">{car.transmission?.value}</span>
                </div>
                <div className="second-col">
                  <div>
                    <IoIosSpeedometer
                      style={{
                        color: "#cf7711",
                      }}
                    />{" "}
                    Мощност
                  </div>{" "}
                  <span className="add-info">{car.engine_power}к.с.</span>
                </div>
              </div>
              <hr />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ width: "50%" }}>
                  <a
                    href="tel:+359884277776"
                    className="call-button"
                    datatype="offer"
                  >
                    <i className="fas fa-phone-alt"></i> Обади се
                  </a>
                </div>
                <div style={{ width: "50%" }}>
                  <a className="offer-button" onClick={handleClick}>
                    <i className="far fa-file-alt"></i> Поискай оферта
                  </a>
                  {isOpen && <OfferForm car={car} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
